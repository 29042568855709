document.addEventListener("DOMContentLoaded", () => {

    const $leftMenu = document.getElementById('left_menu');
    const $menuBtn = document.getElementById('menu_btn');
    const $menu = document.getElementById('menu');


    $menuBtn.addEventListener('click', () => {
        $isClose = $menuBtn.classList.contains('close')
        if ($isClose) {
            /* Abre el Menu */
            $leftMenu.classList.remove('close')
            $leftMenu.classList.add('open')

            /* Rotamos el sombolo */
            $menuBtn.classList.remove('close')
            $menuBtn.classList.add('open')

            $menu.classList.remove('d-none')

        } else {
            /* Cierra el Menu */
            $leftMenu.classList.remove('open')
            $leftMenu.classList.add('close')

            /* Rotamos el sombolo */
            $menuBtn.classList.remove('open')
            $menuBtn.classList.add('close')

            $menu.classList.add('d-none')
        }
    })
});

